:root {
  --day-border-radius: 4px;
  --day-margin: 9px;
  --day-selected-width: 32px;
}

td {
  padding: 0px;
}

/* -------------- CALENDAR DAY -------------- */
/* ------------------------------------------ */
.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--chakra-fontSizes-sm);
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  color: var(--chakra-colors-black);
  background: var(--chakra-colors-white);
  font-weight: var(--chakra-fontWeights-bold);
  border: none;
  outline: none;
}

.CalendarDay__default div:hover {
  color: var(--chakra-colors-blue-500);
}
/* // Controls current day and if current day is selected */
.CalendarDay__today div {
  border: var(--chakra-borders-1px);
  border-radius: var(--day-border-radius);
  border-color: var(--chakra-colors-white);
  margin-left: var(--day-margin);
  margin-right: var(--day-margin);
}
.CalendarDay__today.CalendarDay__selected div {
  color: var(--chakra-colors-white);
  background: var(--chakra-colors-blue-500);
  border: var(--chakra-borders-2px);
  border-radius: var(--day-border-radius);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
}
.CalendarDay__today.CalendarDay__selected.CalendarDay__selected_start div {
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-color: var(--chakra-colors-blue-500);
  box-shadow: none;
  margin-right: 0;
}
/* Same Day Selection; Start and End;
   Same Day Selection; Start; End; Partial/highlighted */
.CalendarDay__selected.CalendarDay__selected_start.CalendarDay__selected_end
  div,
.CalendarDay__highlighted_calendar.CalendarDay__selected.CalendarDay__selected_start.CalendarDay__selected_end
  div {
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  border-color: var(--chakra-colors-white);
  color: var(--chakra-colors-white);
  background: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
  border: var(--chakra-borders-2px);
  margin-left: var(--day-margin);
  margin-right: var(--day-margin);
}
.CalendarDay__selected.CalendarDay__selected_start.CalendarDay__selected_end
  div:hover,
.CalendarDay__highlighted_calendar.CalendarDay__selected.CalendarDay__selected_start.CalendarDay__selected_end
  div:hover {
  background: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
}

.CalendarDay__today.CalendarDay__selected_start.CalendarDay__selected_start_in_hovered_span
  div {
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-color: var(--chakra-colors-blue-500);
  box-shadow: none;
  margin-right: 0;
}
.CalendarDay__hovered_offset {
  background: var(--chakra-colors-gray-100);
  color: inherit;
}
.CalendarDay__outside {
  border: 0;
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-700);
}
.CalendarDay__outside:hover {
  border: 0;
}
.CalendarDay__blocked_minimum_nights {
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-700);
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-700);
}
/* Partial Day - blue dot */
.CalendarDay__highlighted_calendar {
  color: var(--chakra-colors-black);
  background: radial-gradient(
    4px circle at 26px 36px,
    var(--chakra-colors-blue-500) 50%,
    transparent 50%
  );
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: radial-gradient(
    4px circle at 26px 36px,
    var(--chakra-colors-blue-500) 50%,
    transparent 50%
  );
}
.CalendarDay__highlighted_calendar:active > div,
.CalendarDay__highlighted_calendar:hover > div {
  border: none;
  box-shadow: none;
  color: var(--chakra-colors-blue-500);
}

/*  Today and partial day  */
.CalendarDay__today.CalendarDay__highlighted_calendar:hover > div {
  border: none;
  box-shadow: 0 0 0 2px var(--chakra-colors-blue-500);
}
.CalendarDay__today.CalendarDay__highlighted_calendar:active > div {
  border: var(--chakra-borders-2px);
  color: var(--chakra-colors-white);
  background: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-500);
}

.CalendarDay__today.CalendarDay__highlighted_calendar:hover.CalendarDay__selected
  > div {
  border: none;
  box-shadow: none;
}
/* // the span when hovering/selecting days */
.CalendarDay__selected_span div {
  background: var(--chakra-colors-blue-500);
  border: 1px solid var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
}
.CalendarDay__selected_span:active div,
.CalendarDay__selected_span:hover div {
  background: var(--chakra-colors-blue-500);
  border: none;
  color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-none);
  margin-left: 0;
  margin-right: 0;
}
.CalendarDay__selected:active div {
  background: var(--chakra-colors-blue-500);
  border: none;
  color: var(--chakra-colors-white);
}
.CalendarDay__selected:active div,
.CalendarDay__selected:active div {
  background: var(--chakra-colors-blue-500);
  border: none;
  color: var(--chakra-colors-white);
}
.CalendarDay__selected_start.CalendarDay__selected_start_in_hovered_span div {
  width: 41px;
  background: var(--chakra-colors-blue-500);
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.CalendarDay__selected_start:hover div {
  background: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
}
.CalendarDay__selected_start_no_selected_end div {
  color: var(--chakra-colors-white);
  width: 32px;
  margin-left: var(--day-margin);
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
}
.CalendarDay__selected_start div {
  background-color: var(--chakra-colors-blue-500);
  border-bottom-left-radius: var(--day-border-radius);
  color: var(--chakra-colors-white);
  border-top-left-radius: var(--day-border-radius);
  margin-left: var(--day-margin);
}
.CalendarDay__selected_end div {
  background-color: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--day-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: var(--day-border-radius);
  margin-left: 0;
  margin-right: var(--day-margin);
}
.CalendarDay__selected_end:hover div {
  background: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
}
.CalendarDay__hovered_span div {
  background: var(--chakra-colors-blue-500);
  border: none;
  color: var(--chakra-colors-white);
}
/* hovered day when choosing end date */
.CalendarDay__hovered_span:hover div {
  background: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-white);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--day-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: var(--day-border-radius);
  margin-left: 0;
  margin-right: var(--day-margin);
}
.CalendarDay__hovered_span:active div {
  background: var(--chakra-colors-blue-500);
  border: none;
  color: var(--chakra-colors-white);
}
.CalendarDay__blocked_calendar > div {
  background: var(--chakra-colors-white);
  border: none;
  color: var(--chakra-colors-gray-700);
  box-shadow: none;
  font-weight: var(--chakra-fontWeights-normal);
  cursor: not-allowed;
}

.CalendarDay__blocked_calendar:hover > div,
.CalendarDay__blocked_calendar:active > div {
  background: var(--chakra-colors-white);
  border: none;
  color: var(--chakra-colors-gray-700);
  box-shadow: none;
  font-weight: var(--chakra-fontWeights-normal);
  cursor: not-allowed;
}
.CalendarDay__blocked_out_of_range > div,
.CalendarDay__blocked_out_of_range:active > div,
.CalendarDay__blocked_out_of_range:hover > div {
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-700);
  border: none;
  box-shadow: none;
  font-weight: var(--chakra-fontWeights-normal);
  cursor: not-allowed;
}
.CalendarDay__hovered_start_first_possible_end {
  background: var(--chakra-colors-blue-500);
}

.CalendarDay__lastDayOfWeek.CalendarDay__selected div {
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  margin-right: var(--day-margin);
  width: unset;
  color: var(--chakra-colors-white);
}
.CalendarDay__lastDayOfWeek.CalendarDay__selected_span div {
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  margin-right: var(--day-margin);
}
.CalendarDay__lastDayOfWeek.CalendarDay__hovered_span div {
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  margin-right: var(--day-margin);
}
.CalendarDay__firstDayOfWeek.CalendarDay__hovered_span div {
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  margin-left: var(--day-margin);
}
.CalendarDay__selected_start.CalendarDay__lastDayOfWeek.CalendarDay__selected
  div {
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  margin-right: var(--day-margin);
}
.CalendarDay__firstDayOfWeek.CalendarDay__selected_span div {
  border-bottom-left-radius: var(--day-border-radius);
  border-top-left-radius: var(--day-border-radius);
  margin-left: var(--day-margin);
}
.CalendarMonth_caption strong {
  line-height: var(--chakra-lineHeights-short);
  font-size: var(--chakra-fontSizes-sm);
  color: var(--chakra-colors-black);
}
.CalendarDay__after_hovered_start div {
  border-bottom-right-radius: var(--day-border-radius);
  border-top-right-radius: var(--day-border-radius);
  margin-right: var(--day-margin);
}
/* ------------- CALENDAR MONTH ------------- */
/* ------------------------------------------ */
.CalendarMonth {
  background: var(--chakra-colors-white);
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 10px;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}
.CalendarMonth_caption {
  color: var(--chakra-colors-gray-700);
  font-size: var(--chakra-fontSizes-lg);
  text-align: center;
  padding-top: 15px;
  padding-bottom: 32px;
  caption-side: initial;
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}
.CalendarMonthGrid {
  background: var(--chakra-colors-white);
  text-align: left;
  z-index: var(--chakra-zIndices-base);
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: var(--day-margin);
}
.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: var(--chakra-zIndices-hide);
  opacity: 0;
  pointer-events: none;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}

/* ---------- DAYPICKER NAVIGATION ---------- */
/* ------------------------------------------ */
.DayPickerNavigation {
  position: relative;
  z-index: var(--chakra-zIndices-mapOverlay);
}
.DayPickerNavigation__horizontal {
  height: 0;
}
.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1;
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}
.DayPickerNavigation__bottom {
  height: auto;
}
.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}
.DayPickerNavigation_button__default {
  background-color: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-800);
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-400);
}
.DayPickerNavigation_button__default:active {
  background: var(--chakra-colors-gray-100);
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0;
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
}
.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: var(--chakra-colors-white);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: var(--chakra-colors-gray-500); /* fluidgray */
  display: block;
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: var(--chakra-colors-gray-700);
}
.DayPickerNavigation_svg__disabled {
  fill: var(--chakra-colors-gray-100);
}
/* ------------------------------------- */
/* --------- DAYPICKER HEADER ---------- */
/* ------------------------------------- */
#DayPicker_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0;
  position: relative;
}
#DayPicker_header > div {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--chakra-colors-gray-200);
}
#DayPicker_header > div p:first-child {
  margin-right: 10px;
}
#DayPicker_header > div .tab--active {
  font-weight: var(--chakra-fontWeights-bold);
  padding: 3px 5px;
  border-bottom: var(--chakra-borders-2px);
  border-bottom-color: var(--chakra-colors-fluidBlue);
  cursor: pointer;
}

#DayPicker_header > div .tab--default {
  padding: 3px 5px;
  border-bottom: var(--chakra-borders-2px);
  border-bottom-color: transparent;
  cursor: pointer;
}
#DayPicker_header span {
  font-weight: var(--chakra-fontWeights-bold);
  margin-top: 8px;
  color: var(--chakra-colors-fluidBlue);
}

/* ------------------------------------- */
/* ------------- DAYPICKER ------------- */
/* ------------------------------------- */
.DayPicker {
  background: var(--chakra-colors-white);
  position: relative;
  text-align: left;
  min-height: 320px;
  max-height: 100%;
  max-width: 100%;
}
@media screen and (min-width: 48em) {
  .DayPicker {
    background: var(--chakra-colors-white);
    position: relative;
    text-align: left;
    min-height: 320px;
    max-height: 420px;
    max-width: 640px;
  }
  #DayPicker_header span {
    display: none;
  }
}

.DayPicker__horizontal {
  background: var(--chakra-colors-white);
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  border-bottom: none;
  border-radius: var(--chakra-radii-base);
  overflow: hidden;
  z-index: var(--chakra-zIndices-modal);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 48em) {
  .DayPicker__withBorder {
    border-bottom: 1px solid;
    border-color: var(--chakra-colors-gray-400);
    border-radius: var(--chakra-radii-base);
    overflow: hidden;
    z-index: var(--chakra-zIndices-modal);
  }
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 245px;
}
@media screen and (min-width: 48em) {
  .DayPicker_portal__horizontal {
    max-height: 100%;
  }
}
.DayPicker_portal__vertical {
  position: initial;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  color: var(--chakra-colors-gray-700);
  position: absolute;
  top: 53px;
  z-index: var(--chakra-zIndices-mapOverlay);
  text-align: left;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid;
  border-color: var(--chakra-colors-gray-100);
  background: var(--chakra-colors-white);
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: var(--chakra-fontSizes-2xl);
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.DayPicker_transitionContainer__horizontal {
  -webkit--transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
/* --------------------------------------------- */
/* ---------- DATE RANGE PICKER INPUT ---------- */
/* --------------------------------------------- */
/* 
  <div className="DateRangePickerInput">
    <div className="DateInput"> <input className="DateInput_input" /><p /> <div>
    <div className="DateRangePickerInput_arrow"> <svg className="-DividerContainer" /> <div>
    <div className="DateInput"> <input className="DateInput_input" /><p /> <div>
  </div>
*/
.DateRangePickerInput {
  background-color: var(--chakra-colors-white);
  position: relative;
  display: grid;
  width: 100%;
  /* max-width: 300px; */
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  grid-template-rows: repeat(3, auto);
}

@media screen and (min-width: 48em) {
  .DateRangePickerInput {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(210px, 1fr) 38px minmax(210px, 1fr);
    width: fit-content;
  }
}

.DateRangePickerInput__disabled {
  /* background: var(--chakra-colors-gray-50); */
}
.DateRangePickerInput__withBorder {
  border-radius: var(--chakra-radii-sm);
  border: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}
.DateRangePickerInput_arrow {
  display: none;
}
@media screen and (min-width: 48em) {
  .DateRangePickerInput_arrow {
    opacity: 0;
    display: flex;
    vertical-align: middle;
    border: var(--chakra-borders-1px);
    border-color: var(--chakra-colors-transparent);
    background-color: var(--chakra-colors-gray-600);
    border-radius: var(--chakra-radii-base);
    width: 1px;
  }
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -web-kit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: var(--chakra-colors-gray-100);
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: var(--chakra-colors-gray-500);
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: var(--chakra-colors-gray-500);
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
/* -------------------------------- */
/* ---------- DATE INPUT ---------- */
/* -------------------------------- */
/* 
  <div class="DateInput">
    ::before <--- label
    <input class="DateInput_input" />
    <p class="screenReaderMessage" />
    ::after <--- caretDown svg
  </div> 
*/
.DateInput {
  margin: 0;
  padding: 0;
  background: var(--chakra-colors-white);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.DateInput:focus-visible {
  outline: none;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: var(--chakra-colors-gray-50);
  color: var(--chakra-colors-gray-500);
}
/* --------------------------------------------- */
/* ---------- DATE RANGE PICKER INPUT ---------- */
/* --------------------------------------------- */
.DateInput_input {
  font-weight: var(--chakra-fontWeights-medium);
  font-size: var(--chakra-fontSizes-md);
  line-height: var(--chakra-lineHeights-8);
  color: var(--chakra-colors-black);
  background-color: var(--chakra-colors-white);
  width: 100%;
  padding: 0;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid var(--chakra-colors-gray-700);
  border-left: 0;
  border-radius: var(--chakra-radii-none);
  position: relative;
}
.DateInput_input::placeholder {
  padding-left: 0;
}
.DateInput_input:focus-visible {
  outline: none;
}

.DateInput_input__small {
  font-size: var(--chakra-fontSizes-xs);
  line-height: var(--chakra-lineHeights-5);
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  outline: 0;
  background: white;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: var(--chakra-borders-2px);
  border-color: var(--chakra-colors-blue-300);
  border-left: 0;
}
.DateInput_input__disabled {
  background: var(--chakra-colors-gray-50);
  font-style: italic;
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  display: none;
}
/* --------------------------------------- */
/* ---------- DATE RANGE PICKER ---------- */
/* --------------------------------------- */
.DateRangePicker {
  width: 100%;
  position: relative;
  display: inline-block;
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  z-index: var(--chakra-zIndices-calendar);
  background-color: var(--chakra-colors-white);
  position: absolute;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
  border-radius: var(--chakra-radii-base);
}
.DateRangePicker_picker__directionRight {
  right: 0;
  border-radius: var(--chakra-radii-base);
}
.DateRangePicker_picker__portal {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  z-index: var(--chakra-zIndices-popover);
}
.DateRangePicker_picker__fullScreenPortal {
  background: var(--chakra-colors-white);
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  z-index: var(--chakra-zIndices-mapOverlay);
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: var(--chakra-colors-gray-400);
}
/* ------------------------------------------------ */
/* ---------- DayPickerKeyboardShortcuts ---------- */
/* ------------------------------------------------ */
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: var(--chakra-radii-none);
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: var(--chakra-fontSizes-sm);
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: var(--chakra-zIndices-mapOverlay);
}
.DayPickerKeyboardShortcuts_show::before {
  content: '';
  display: block;
  position: absolute;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid;
  border-color: var(--chakra-colors-blue-500);
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid var(--chakra-colors-blue-300);
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid;
  border-color: var(--chakra-colors-blue-500);
  top: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid var(--chakra-colors-blue-300);
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid;
  border-color: var(--chakra-colors-blue-500);
  top: 0;
  left: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid var(--chakra-colors-blue-300);
}
.DayPickerKeyboardShortcuts_showSpan {
  color: var(--chakra-colors-white);
  position: absolute;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: var(--chakra-colors-white);
  border: 1px solid var(--chakra-colors-gray-100);
  border-radius: var(--chakra-radii-sm) 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: var(--chakra-zIndices-mapOverlay);
  padding: 22px;
  margin: 33px;
  text-align: left;
}
.DayPickerKeyboardShortcuts_title {
  font-size: var(--chakra-fontSizes-md);
  font-weight: var(--chakra-fontWeights-bold);
  margin: 0;
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: var(--chakra-fontSizes-sm);
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: var(--chakra-zIndices-mapOverlay);
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: var(--chakra-colors-gray-400);
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: var(--chakra-colors-gray-500);
}
/* ------------------------------------------------ */
/* ---------- DayPickerKeyboardShortcuts ---------- */
/* ------------------------------------------------ */
.SingleDatePickerInput {
  display: flex;
  width: 100%;
  background-color: var(--chakra-colors-white);
}
.SingleDatePickerInput__withBorder {
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}
.SingleDatePickerInput__withBorder:focus {
  border-bottom: var(--chakra-borders-2px);
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}
.SingleDatePickerInput__disabled {
  background-color: var(--chakra-colors-gray-100);
}
.SingleDatePickerInput__block {
  display: block;
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -web-kit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SingleDatePickerInput > .DateInput {
  font-weight: var(--chakra-fontWeights-normal);
  font-size: var(--chakra-fontSizes-sm);
  line-height: var(--chakra-lineHeights-8);
  color: var(--chakra-colors-darkGray);
  background-color: var(--chakra-colors-white);
  padding-bottom: 2px;
  padding-top: 6px;
  border: 0px;
  border-radius: var(--chakra-radii-none);
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: var(--chakra-colors-gray-100);
  border-radius: 50%;
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}
.SingleDatePickerInput_clearDate_svg {
  fill: var(--chakra-colors-gray-500);
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  padding-right: 0px;
  margin-right: 2px;
  /* margin-left: 10px; */
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: var(--chakra-colors-gray-500);
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  z-index: 10;
  background-color: var(--chakra-colors-white);
  position: absolute;
  border-radius: 3px;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background: var(--chakra-colors-white);
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: var(--chakra-colors-gray-400);
  text-decoration: none;
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: var(--chakra-colors-gray-400);
}
